//
// export default RadialMap;

// import { slice } from "core-js/fn/array";


function log(lvl, msg) {
    const maxLevel = 2;
    if (maxLevel == 0) return;
    if (msg === undefined) console.log(lvl);
    if (lvl <= maxLevel) console.log(msg);
}


// var labels = {
//     'a': 'Avaiki',
//     'e': 'Enuamanu',
//     'i': 'Itimanuka',
//     'ngo': 'Ngotare',
//     'ka': 'Kakaia',
//     'ke': 'Kena',
//     'ki': 'Kiva',
//     'ko': 'Kopeka',
//     'ku': 'Kura',
//     'ma': 'Manava',
//     'mo': 'Momore',
//     'mu': 'Muramura',
//     'na': 'Natura',
//     'ta': "Tangaroa",
//     'te': 'Teatea',
//     'to': 'Toa',
//     'tu': 'Tumu',
//     'ra': 'Ra',
//     're': 'Renga',
//     'ro': "Rongo",
//     'ru': 'Rupe',
//     've': 'Vero'
// }

// let s2Divs = [5, 5];

let ALPHABET = 'abcdefghijklmnopqrstuvwxyz';

const EARTH_RADIUS = 6378137;  // in metres
// const ALPHA = Math.PI / 180;
// const OMEGA = 1 / (ALPHA * EARTH_RADIUS);

function deg2rad(deg) {
    return deg * Math.PI / 180.0;
}

function distance(latlon1, latlon2) {
    let lat1 = latlon1[0];
    let lon1 = latlon1[1];
    let lat2 = latlon2[0];
    let lon2 = latlon2[1];


    let dLat = deg2rad(lat2 - lat1);
    let dLon = deg2rad(lon2 - lon1);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return EARTH_RADIUS * c;  // Distance in metres
}

function toRightOfLine(xy, xy1, xy2) {
    return outerProduct(xy, xy1, xy2) >= 0.0;
}

function toLeftOfLine(xy, xy1, xy2) {
    // if (xy1[0] == xy2[0] && xy1[1] == xy2[1]) return true;
    return toRightOfLine(xy, xy1, xy2) == false;
}

function outerProduct(xy, xy1, xy2) {
    return (xy[0] - xy1[0]) * (xy2[1] - xy1[1]) - (xy[1] - xy1[1]) * (xy2[0] - xy1[0]);
}

function interpolateXi(xi, pt0, pt1) {
    let phi0 = 1 - xi;
    let phi1 = xi;
    return [phi0 * pt0[0] + phi1 * pt1[0], phi0 * pt0[1] + phi1 * pt1[1]];
}


function generateAddresses(names) {
    const vowels = ['a', 'e', 'i', 'o', 'u'];
    const constanants = ['ng', 'k', 'm', 'n', 'p', 'r', 't', 'v'];
    let letters = [];
    var key;
    var name;
    for (let j = 0; j < 5; j++) {
        key = vowels[j] + "1";
        name = key in names ? names[key]["name"] : "";
        if (name) {
            letters.push(name);
        } else {
            letters.push(vowels[j].toUpperCase() + "tai");
        }
        key = vowels[j] + "2";
        name = key in names ? names[key]["name"] : "";
        if (name) {
            letters.push(name);
        } else {
            letters.push(vowels[j].toUpperCase() + "rua");
        }
    }
    for (let i = 0; i < 8; i++) {
        let letter = constanants[i].toUpperCase();
        if (letter === 'NG') letter = 'Ng';
        for (let j = 0; j < 5; j++) {
            key = constanants[i] + vowels[j] + "1";
            name = key in names ? names[key]["name"] : "";
            if (name) {
                letters.push(name);
            } else {
                letters.push(letter + vowels[j] + "tai");
            }
            key = constanants[i] + vowels[j] + "2";
            name = key in names ? names[key]["name"] : "";
            if (name) {
                letters.push(name);
            } else {
                letters.push(letter + vowels[j] + "rua");
            }
        }
    }
    return letters;
}

class Coords {
    constructor(lat, lon) {
        if (lon === undefined) {
            this.lat = lat[0];
            this.lon = lat[1];
        } else {
            this.lat = lat;
            this.lon = lon;
        }
        this.latlon = [this.lat, this.lon];
        this.lonlat = [this.lon, this.lat];
    }

    equals(pt) {
        return this.lat == pt.lat && this.lon == pt.lon;
    }
}



class Slice {
    constructor(idx, radial0, radial1, divs) {
        this.isValid = false;
        this.idx = idx;
        if (radial0 === undefined && radial1 === undefined) return;
        this.r0 = radial0;
        this.r1 = radial1;
        this.divs = divs;
        this.lastIdx = radial0.length - 1
        this.isValid = true;
    }

    isSector() {
        return this.r0[this.lastIdx].equals(this.r1[this.lastIdx])
    }

    containsLatLon(latLon) {
        let pt = [latLon.lng, latLon.lat];
        if (this.isSector()) {
            if (toLeftOfLine(pt, this.r0[0].lonlat, this.r0[this.lastIdx].lonlat)
                && toLeftOfLine(pt, this.r1[this.lastIdx].lonlat, this.r1[0].lonlat)
                && toLeftOfLine(pt, this.r1[0].lonlat, this.r0[0].lonlat)) {
                return true;
            }
        } else {
            if (toLeftOfLine(pt, this.r0[0].lonlat, this.r0[this.lastIdx].lonlat)
                && toLeftOfLine(pt, this.r1[this.lastIdx].lonlat, this.r1[0].lonlat)
                && toLeftOfLine(pt, this.r1[0].lonlat, this.r0[0].lonlat)
                && toLeftOfLine(pt, this.r0[this.lastIdx].lonlat, this.r1[this.lastIdx].lonlat)) {
                // console.log("MapBlocks: Found Slice in " + (d.getTime() - tic) + " ms");
                return true;
            }
        }
        return false;
    }

    getSection(idx) {
        let sectionBlock = new Block(idx, [this.r0[idx], this.r0[idx + 1], this.r1[idx], this.r1[idx + 1]]);
        sectionBlock.slice = this;
        return sectionBlock;
    }

    getBlock(idx) {
        // find radial section
        var totalDivs = 0;
        var section = 0;
        var sectionIdx = idx;
        for (let i = 0; i < this.divs.length; i++) {
            if (idx < totalDivs + this.divs[i]) {
                section = i;
                sectionIdx = idx - totalDivs;
                break;
            }
            totalDivs += this.divs[i];
        }

        let sectionBlock = new Block(idx, [this.r0[section], this.r0[section + 1], this.r1[section], this.r1[section + 1]]);
        let blk = sectionBlock.calcSubBlock(sectionIdx, 0, this.divs[section], 1);
        blk.idx = idx;
        blk.slice = this;
        return blk;
    }

    findBlock(latlng, divs, blocksPerRadial) {
        // this function assumes the block does exist in the slice. This should be the case if "findSlice" is used.
        let pt = [latlng.lng, latlng.lat];
        for (let i = 0; i < blocksPerRadial - 1; i++) {
            let blk = this.getBlock(i);
            let pts = blk.pts;
            if (toLeftOfLine(pt, pts[1].lonlat, pts[3].lonlat)) {
                return blk;
            }
        }
        return this.getBlock(this.blocksPerRadial - 1);
    }
}


class Block {
    constructor(idx, pts) {
        this.isValid = false;
        this.idx = idx;
        if (pts === undefined || !pts[0].lat || !pts[0].lon) return;
        // if (pts.length != 3 || pts.length != 4) return;
        this.pts = pts;
        // this.isQuad = pts.length = 4;
        this.slice = -1;
        this.latLng = null;
        this.address = "";
        this.subLetters = "";
        this.divs = null;
        this.subBlock = null;
        this.isValid = true;
    }

    isSector() {
        return this.pts[1].equals(this.pts[3]);
    }

    setSlice(slice) {
        this.slice = slice;
    }

    setAddress(address) {
        this.address = address;
    }

    getAddress() {
        return this.address;
    }

    getSubLetters() {
        return this.subLetters;
    }

    setLatLng(latLng) {
        if (this.latLng == null || this.latLng.lat !== latLng.lat || this.latLng.lng !== latLng.lng) {
            this.subBlock = null;
        }

        this.latLng = latLng;
    }

    getLatLng() {
        if (this.latLng == null) {
            var lat = 0.0;
            var lng = 0.0;
            for (let idx = 0; idx < this.pts.length; idx++) {
                lat += this.pts[idx].lat;
                lng += this.pts[idx].lon;
            }
            lat = lat / this.pts.length;
            lng = lng / this.pts.length;

            this.latLng = {lat: lat, lng: lng};
        }

        return this.latLng;
    }

    getShapeString() {
        var dc = distance(this.pts[0].latlon, this.pts[2].latlon);
        let dr = distance(this.pts[0].latlon, this.pts[1].latlon);
        if (dc >= 10) { dc = dc.toFixed(0) } else { dc = dc.toFixed(1) }
        if (dr >= 10) { dr = dr.toFixed(0) } else { dr = dr.toFixed(1) }
        return dc + " x " + dr + " m";
    }

    containsLatLon(latLon) {
        let pt = [latLon.lng, latLon.lat];
        if (this.isSector()) {
            if (toLeftOfLine(pt, this.pts[0].lonlat, this.pts[1].lonlat)
                && toLeftOfLine(pt, this.pts[1].lonlat, this.pts[2].lonlat)
                && toLeftOfLine(pt, this.pts[2].lonlat, this.pts[0].lonlat)) {
                return true;
            }
        } else {
            if (toLeftOfLine(pt, this.pts[0].lonlat, this.pts[1].lonlat)
                && toLeftOfLine(pt, this.pts[1].lonlat, this.pts[3].lonlat)
                && toLeftOfLine(pt, this.pts[3].lonlat, this.pts[2].lonlat)
                && toLeftOfLine(pt, this.pts[2].lonlat, this.pts[0].lonlat)) {
                // console.log("MapBlocks: Found Slice in " + (d.getTime() - tic) + " ms");
                return true;
            }
        }
    }

    calcDivs() {
        var dc = distance(this.pts[0].latlon, this.pts[2].latlon);
        let dr = distance(this.pts[0].latlon, this.pts[1].latlon);

        let charLength = 1.0 / Math.sqrt(dr * dc / 26);
        var nr = Math.round(dr * charLength);
        var nc = Math.round(dc * charLength);

        if (nr > 26) { nr = 26; }
        if (nr === 11 || nr === 12) { nr = 13; }
        while (nc * nr > 26) {
            nc -= 1;
        }

        return [nr, nc];
    }

    getPolygon() {
        if (this.isSector()) {
            return [this.pts[0].latlon, this.pts[1].latlon, this.pts[2].latlon];
        }
        return [this.pts[0].latlon, this.pts[1].latlon, this.pts[3].latlon, this.pts[2].latlon];
    }

    getSlicePolygon() {
        let polygons = [];
        if (this.slice.isSector()) {
            polygons.push( [this.pts[1].latlon, this.slice.r0[this.slice.lastIdx].latlon, this.pts[3].latlon]);
        } else {
            polygons.push( [this.pts[1].latlon, this.slice.r0[this.slice.lastIdx].latlon, this.slice.r1[this.slice.lastIdx].latlon, this.pts[3].latlon]);
        }
        polygons.push( [this.slice.r0[0].latlon, this.pts[0].latlon, this.pts[2].latlon, this.slice.r1[0].latlon]);
        return polygons;
    }

    calcSubBlock(rIdx, cIdx, rDivs, cDivs) {
        var dXi = 1 / rDivs;
        var xiA = rIdx * dXi;
        var xiB = xiA + dXi;

        let radialBlock = [
            interpolateXi(xiA, this.pts[0].latlon, this.pts[1].latlon),
            interpolateXi(xiB, this.pts[0].latlon, this.pts[1].latlon),
            interpolateXi(xiA, this.pts[2].latlon, this.pts[3].latlon),
            interpolateXi(xiB, this.pts[2].latlon, this.pts[3].latlon)
        ]

        let dXiC = 1 / cDivs;
        let xiC0 = cIdx * dXiC;
        let xiC1 = xiC0 + dXiC;

        let subBlockPts = [
            new Coords(interpolateXi(xiC0, radialBlock[0], radialBlock[2])),
            new Coords(interpolateXi(xiC0, radialBlock[1], radialBlock[3])),
            new Coords(interpolateXi(xiC1, radialBlock[0], radialBlock[2])),
            new Coords(interpolateXi(xiC1, radialBlock[1], radialBlock[3]))
        ];
        let idx = cIdx * rDivs + rIdx;
        return new Block(idx, subBlockPts);
    }

    getSubBlockByLetter(letter) {
        if (this.divs == null) this.divs = this.calcDivs();
        let idx = ALPHABET.indexOf(letter);
        let cIdx = Math.floor(idx / this.divs[0]);
        let rIdx = idx - cIdx * this.divs[0];
        let subBlock = this.calcSubBlock(rIdx, cIdx, this.divs[0], this.divs[1])
        subBlock.getLatLng()
        subBlock.subLetters = this.subLetters  + letter;
        return subBlock;
    }

    getSubBlock() {

        if (this.subBlock == null) {
            let latlng = this.getLatLng();

            if (this.divs == null) this.divs = this.calcDivs();

            let nr = this.divs[0];
            let nc = this.divs[1];

            let pt = [latlng.lng, latlng.lat];
            var idxR = -1;
            var idxC = -1;
            for (let i = 0; i < nr; i++) {
                let block = this.calcSubBlock(i, 0, nr, 1);
                if (toLeftOfLine(pt, block.pts[1].lonlat, block.pts[3].lonlat)) {
                    idxR = i;
                    break;
                }
            }
            for (let i = 0; i < nc; i++) {
                let block = this.calcSubBlock(0, i, 1, nc);
                if (toLeftOfLine(pt, block.pts[3].lonlat, block.pts[2].lonlat)) {
                    idxC = i;
                    break;
                }
            }

            this.subBlock = this.calcSubBlock(idxR, idxC, nr, nc);
            this.subBlock.setLatLng(latlng);
            let sIdx = this.subBlock.idx;
            this.subBlock.subLetters = this.subLetters  + ALPHABET.substring(sIdx, sIdx + 1);
        }

        return this.subBlock;
    }
}

export default class RadialMap {

    constructor(radialMap) {

        let radials = radialMap['radials'];
        let divs = radialMap['sections'];

        // let re1 = new RegExp('ab+c');

        this.divs = divs;
        this.blocksPerRadial = 0;
        for (let idx in divs) {
            this.blocksPerRadial += divs[idx];
        }

        this.totalRadials = radials.length;
        this.slicesPerHour = this.totalRadials / 12;
        this.lastIdx = radials[0].length - 1;

        this.radials = [];
        for (let i = 0; i < radials.length; i++) {
            let radial = radials[i];
            let pts = [];
            for (let j = 0; j < radial.length; j++) {
                pts.push(new Coords(radial[j][0], radial[j][1]));
            }
            this.radials.push(pts);
        }

        this.block = new Block();

        this.reNumbers = new RegExp('^[0-9]+');
        this.reSubletters = new RegExp('[a-z]+$');
        this.addresses = generateAddresses({});
    }

    loadAddresses(names) {
        this.addresses = generateAddresses(names)
    }

    getAddress(block) {
        if (block === undefined) return this.getAddress(this.block);
        if (!block.isValid) return ""
        let sliceIdx = block.slice.idx + 1;
        let hour = Math.floor(sliceIdx / this.slicesPerHour);
        let minute = sliceIdx - hour * this.slicesPerHour;
        if (hour > 0 && minute < 10) minute = "0" + minute;
        if (hour === 0) {
            return minute + " " + this.addresses[block.idx];
        }
        return hour + "" + minute + " " + this.addresses[block.idx];
    }

    searchAddress(searchText) {
        let results = [];

        let split = searchText.replace(/  +/g, ' ').split(" ");
        if (split.length < 2) return results;
        let number = split[0].toLowerCase();

        let name = split[1].toLowerCase();

        if (name.length == 0) return results;

        var letters = "";
        if (name.length == 1) {
            letters = name[0].toUpperCase();
        }
        else if (name.length == 2) {
            letters = name[0].toUpperCase() + name[1];
        }
        else {
            if (name[0] == 'n' && name[1] == 'g') {
                letters = name[0].toUpperCase() + name[1] + name[2];
            } else {
                letters = name[0].toUpperCase() + name[1];
            }
        }

        if (letters == "") return results;

        for (var i in this.addresses) {
            let address = this.addresses[i];
            if (this.addresses[i].startsWith(letters)) {
                results.push(number + " " + address + " Blk");
            }
        }

        return results;

    }

    getSlice(idx) {
        return new Slice(idx, this.getRadial(idx), this.getRadial(idx + 1), this.divs);
    }

    getRadial(idx) {
        if (idx >= this.totalRadials) return this.radials[idx - this.totalRadials];
        if (idx < 0) return this.radials[idx + this.totalRadials];
        return this.radials[idx];
    }

    findBlock(latLng) {
        if (this.block.isValid) {
            if (this.block.containsLatLon(latLng)) {
                log(3, 'No search');
                this.block.setLatLng(latLng);
                return this.block;
            }
            if (this.block.slice.containsLatLon(latLng)) {
                log(3, 'Slice search');
                this.block = this.block.slice.findBlock(latLng, this.divs, this.blocksPerRadial);
            } else {
                log(3, 'Full search');
                let slice = this.findSlice(latLng);
                if (slice.isValid) {
                    this.block = slice.findBlock(latLng, this.divs, this.blocksPerRadial);
                } else {
                    this.block = new Block();
                }

            }
        } else {
            log(3, 'New search');
            let slice = this.findSlice(latLng);
            if (slice.isValid) {
                this.block = slice.findBlock(latLng, this.divs, this.blocksPerRadial);
            } else {
                this.block = new Block();
            }
        }
        this.block.setLatLng(latLng);

        return this.block;
    }

    findBlockByAddress(address) {
        let split = address.replace(/  +/g, ' ').split(" ");
        if (split.length < 2) return null;

        let matchNumbers = split[0].match(this.reNumbers);

        if (matchNumbers == null) return;

        var number = parseInt(matchNumbers[0]) - 1;
        if (number === 1200) number = 0;
        var hour = Math.floor(number / 100);
        let sliceIdx = hour * this.slicesPerHour + (number - hour * 100)

        let name = split[1].toLowerCase();
        if (name.length === 0) return null;

        var blockIdx = -1;
        for (let i = 0; i < this.addresses.length; i++) {
            if (this.addresses[i].toLowerCase().startsWith(name)) {
                blockIdx = i;
                break
            }
        }

        let slice = new Slice(sliceIdx, this.getRadial(sliceIdx), this.getRadial(sliceIdx + 1), this.divs);
        this.block = slice.getBlock(blockIdx);

        let matchSubletters = split[0].match(this.reSubletters);
        if (matchSubletters != null) {
            let subLetters = matchSubletters[0].split("");
            console.log(subLetters);
            if (subLetters.length > 0) {
                let s1Block = this.block.getSubBlockByLetter(subLetters[0]);
                if (subLetters.length > 1) {
                    let s2Block = s1Block.getSubBlockByLetter(subLetters[1]);
                    this.block.setLatLng(s2Block.getLatLng())
                } else {
                    this.block.setLatLng(s1Block.getLatLng())
                }
            }
        } else {
            this.block.getLatLng();
            this.block.subBlock = null;
        }

        return this.block;
    }

    getSlicePolygonPts(slice) {
        let pts = [];

        // add long edge of start minute
        var radial = this.getRadial(slice);
        pts.push(radial[0].latlon);
        pts.push(radial[this.lastIdx].latlon);

        // add long edge of end minute
        radial = this.getRadial(slice + 1);
        pts.push(radial[this.lastIdx].latlon);
        pts.push(radial[0].latlon);

        return pts;
    }

    findSlice(latLng) {
        return this.findSliceInRange(latLng, 0, this.totalRadials);
    }

    findSliceInHour(latLng, hour) {
        return this.findSliceInRange(latLng, hour * this.slicesPerHour, (hour + 1) * this.slicesPerHour);
    }

    findSliceInRange(latLng, slice0, slice1) {
        // var d = new Date();
        // let tic = d.getTime();
        for (let i = slice0; i < slice1; i++) {
            let slice = new Slice(i, this.getRadial(i), this.getRadial(i + 1), this.divs);
            if (slice.containsLatLon(latLng)) return slice;
        }
        // console.log("MapBlocks: Failed to find Slice in " + (d.getTime() - tic) + " ms");
        return new Slice();
    }

    getRingPolygon(index) {
        let pts = [];

        // add long edge of start minute
        for (let i in this.radials) {
            var radial = this.getRadial(i);
            pts.push(radial[index].latlon);
        }
        return pts;
    }

    getClockOutline() {
        let pts = [];

        // add long edge of start minute
        let outline = [];
        for (let i in this.radials) {
            var radial = this.getRadial(i);
            outline.push(radial[0].latlon);
        }
        let r0 = this.getRadial(0);
        outline.push(r0[0].latlon);
        pts.push(outline);

        for (let i = 0; i < 12; i++) {
            let radial = this.getRadial(i * this.slicesPerHour);
            pts.push([radial[0].latlon, radial[this.lastIdx].latlon]);
        }

        let r3 = this.getRadial(3 * this.slicesPerHour);
        let r9 = this.getRadial(9 * this.slicesPerHour);
        pts.push([r3[this.lastIdx].latlon, r9[this.lastIdx].latlon]);

        return pts;
    }

}
