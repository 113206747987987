<template>
  <div id="map-container"></div>
</template>

<script>
/* eslint-disable no-useless-escape */

import { getPlatforms } from '@ionic/vue';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';

import RadialMap from "@/RadialMap.js";

export default {
  name: 'RadialMapView',
  components: {

  },
  data: () => ({
    name: "radial_map_100min",
    mapIsSetup: false,
    map: null,
    radialMap: null,
    radialMaps: {},
    block: null,
    polygons: {},
    firstClick: true,
    clickedLatLng: null
  }),
  methods: {

    log(msg) {
      console.log(msg);
    },

    setupMap() {
      if (!this.mapIsSetup) {
        console.log('Setup Map ');

        let platforms = getPlatforms();

        let center = [-21.237, -159.77746868];
        // let bounds = [[-21.32176223, -159.92763485], [-21.15896208, -159.6337505]];
        // let fitBounds = [[-21.30, -159.9], [-21.18, -159.66]];
        let maxBounds = [[-21.29, -159.87], [-21.19, -159.70]];

        var zoom = 12;
        this.hourLabelClasses = "label label-mobile";
        if (platforms.includes("desktop")) {
          zoom = 14;
          this.hourLabelClasses = "label"
          // zoom = 17;
        }
        let url = 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';

        let map = L.map("map-container", {zoomControl: false, attributionControl: false})
            .setView(center, zoom).setMaxBounds(maxBounds);
        // map.fitBounds(maxBounds);

        L.tileLayer(url, {
          minZoom: 12,
          maxZoom: 21,
          maxNativeZoom: 18,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          attribution: '&copy; 2020 Google'
        }).addTo(map);

        L.control.attribution({
          position: 'bottomleft'
        }).addTo(map);

        L.control.zoom({
          position:'bottomright'
        }).addTo(map);

        map.on('click', this.onClick);
        map.on('mousemove', this.onMove);

        this.map = map;
        this.mapIsSetup = true;

        this.info = L.control();

        // eslint-disable-next-line no-unused-vars
        this.info.onAdd = function (map) {
          this._div = L.DomUtil.create('div', 'info');
          this.update();
          return this._div;
        };

        this.info.update = function (innerHTML) {
          if (innerHTML === undefined || innerHTML.length === 0) {
            this._div.hidden = true;
          } else {
            this._div.hidden = false;
            this._div.innerHTML = innerHTML;
          }
        };

        this.info.addTo(this.map);
        this.updateInfo()

        this.initPolygon('hours', 'green', 0, 0);
        this.initPolygon('block', '#00FFFF', 0, 2);
        this.initPolygon('slice', 'lightblue', 0.2, 1);
        this.initPolygon('subblock1', '#faa142', 0, 2);
        this.initPolygon('subblock2', '#f34949', 0, 2);
        this.initPolygon('selected_block', '#00FFFF', 0, 3);
      }

      this.downloadRadials("radial_map_100min");

    },

    getBlockDetails() {
      // return this.radialMap.getAddress(this.block);
      // let mapName = this.name === "radial_map_100min" ? "MAP 100" : "MAP 60";
      if (this.block == null || !this.block.isValid) return '';



      this.block.setAddress(this.radialMap.getAddress(this.block));
      let address = this.block.getAddress();
      let sb1 = this.block.getSubBlock();
      let sb2 = sb1.getSubBlock();

      let innerHTML = '<div>'
          + '<b class="block0">' + address.split(" ")[0] + ': ' + this.block.getShapeString() + '</b>'
          + '<b class="block1">' + sb1.getSubLetters() + ': ' + sb1.getShapeString() + '</b>'
          + '<b class="block2">' + sb2.getSubLetters() + ': ' + sb2.getShapeString() + '</b>'
          // + '<b>' + mapName + '</b>' +
          '</div>'
      // + '<br /><b>Lat/Lon:</b> ' + block.latLng.lat.toFixed(5) + ', ' + block.latLng.lng.toFixed(5);

      return innerHTML;
    },

    downloadRadials(name) {
      fetch("assets/" + name + ".json")
          .then(response => response.json())
          .then(data => (this.loadRadialMap(name, data)));
    },

    downloadAddresses() {
      fetch("assets/names.json")
          .then(response => response.json())
          .then(data => (this.loadAddresses(data)));
    },

    loadRadialMap(name, radialMapData) {
      this.radialMaps[name] = new RadialMap(radialMapData)

      this.downloadAddresses();

      if (name === this.name) {
        this.radialMap = this.radialMaps[name];

        let clockLines = this.radialMap.getClockOutline();
        L.polyline(clockLines, {color: '#fff4f4', weight: 2}).addTo(this.map);

        for (let hr = 1; hr < 13; hr++) {
          var idx = hr * this.radialMap.slicesPerHour;
          if (hr === 12) idx = 0;
          let radial = this.radialMap.getRadial(idx);
          L.marker(radial[0].latlon, {
            icon: L.divIcon({
              iconSize: null,
              className: this.hourLabelClasses,
              html: '<div class="label' + hr + '">' + hr + '00</div>'
            })
          }).addTo(this.map);
        }
      }

    },

    loadAddresses(names) {
      this.radialMaps[this.name].loadAddresses(names)
    },

    searchAddress(searchText) {
      return this.radialMap.searchAddress(searchText);
    },

    setAddress(address) {
      let block = this.radialMap.findBlockByAddress(address);
      if (block.isValid) {
        this.block = block;
        // this.updateInfo(this.block);
        this.updatePolygons(this.block, true);
        this.map.flyTo(block.getLatLng(), 17);
        this.firstClick = false;
        this.$emit('onMapUpdated');
      }
    },

    toggleRadialMap() {

      if (this.name === "radial_map_100min") {
        this.name = "radial_map_60min"
      } else {
        this.name = "radial_map_100min"
      }

      if (this.name in this.radialMaps) {
        this.radialMap = this.radialMaps[this.name];

        if (this.clickedLatLng != null) {
          this.block = this.radialMap.findBlock(this.clickedLatLng);
          if (this.block.isValid) {
            this.updatePolygons(this.block, true);
          }
          this.$emit('onMapUpdated');
        }

      } else {
        this.downloadRadials();
      }

      this.updateInfo();

      return "Map100"
    },

    eventNotValid(e) {
      if (!e) return true;
      if (!e.latlng) return true;
      if (!e.latlng.lat) return true;
      if (!e.latlng.lng) return true;
      return false;
    },

    onClick(e) {
      if (this.eventNotValid(e)) return;

      this.block = this.radialMap.findBlock(e.latlng);

      if (this.block.isValid) {
        this.updateInfo();
        this.updatePolygons(this.block, true);

        if (this.firstClick) {
          this.map.setView(e.latlng, 19);
          this.firstClick = false;
        }
        this.clickedLatLng = e.latlng;
      }
      this.$emit('onMapUpdated');

    },

    onMove(e) {
      if (this.firstClick) return;
      if (this.eventNotValid(e)) return;
      let block = this.radialMap.findBlock(e.latlng);
      if (block.isValid) {
        this.updatePolygons(block, false);
      }
    },

    updateInfo() {
        this.info.update(this.getBlockDetails());
    },

    updatePolygons(block, isSelected) {
      if (block.idx !== this.getPolygonId('block') || block.slice.idx !== this.getPolygonId('slice')) {
        this.setPolygonId('block', block.idx);
        this.setPolygonId('slice', block.slice.idx);

        this.polygons['block'].setLatLngs(block.getPolygon())
        this.polygons['slice'].setLatLngs(block.getSlicePolygon())
      }

      if (isSelected) {
        let sb1 = block.getSubBlock();
        let sb2 = sb1.getSubBlock();
        this.polygons['selected_block'].setLatLngs(block.getPolygon())
        this.polygons['subblock1'].setLatLngs(sb1.getPolygon())
        this.polygons['subblock2'].setLatLngs(sb2.getPolygon())

        // if (this.name === "radial_map_60min") {
        //   if ("radial_map_100min" in this.radialMaps) {
        //     let otherBlock = this.radialMaps["radial_map_100min"].findBlock(block.getLatLng());
        //     this.polygons['other_block'].setLatLngs(otherBlock.getPolygon());
        //   }
        // } else {
        //   if ("radial_map_60min" in this.radialMaps) {
        //     let otherBlock = this.radialMaps["radial_map_60min"].findBlock(block.getLatLng());
        //     this.polygons['other_block'].setLatLngs(otherBlock.getPolygon());
        //   }
        // }
      }
    },

    setPolygonId(name, id) {
      return this.polygons[name].options.id = id;
    },

    getPolygonId(name) {
      return this.polygons[name].options.id;
    },

    initPolygon(name, color, fillOpacity, stroke) {
      let zero_pts = [[0, 0], [0, 0], [0, 0]];
      if (stroke === 0) {
        this.polygons[name] = L.polygon(zero_pts,
            {id: "", color: color, fillOpacity: fillOpacity, stroke: false}).addTo(this.map)
      } else {
        this.polygons[name] = L.polygon(zero_pts,
            {id: "", color: color, fillOpacity: fillOpacity, stroke: true, weight: stroke}).addTo(this.map)
      }
    },

    hidePolygons() {

    },




  },
  mounted() {
    this.setupMap();
    // Required to render properly after the div sizes have been set to 100%.
    // setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 500);
    // this.checkMapWindowSize();
    window.leafletMap = this.map;
    checkMapWindowSize();
  }
};

function checkMapWindowSize() {
  console.log(window.leafletMap._size.x, window.innerWidth)
  if (window.leafletMap._size.x === 0) {
    window.dispatchEvent(new Event('resize'))
    setTimeout(function() { checkMapWindowSize() }, 500);
  }
}

</script>

<style>
#map-container {
  width: 100%;
  height: 100%;
}

.info {
  padding: 6px 8px;
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255,255,255,0.8);
  border-radius: 5px;
  /*min-height: 16em;*/
  /*min-width: 16em;*/
}
.info h3 {
  font-size: 1.4em;
  margin: 0 0 5px;
  min-height: 3em;
}

.info h4 {
  font-size: 1.2em;
  margin: 0 0 5px;
  color: #777; }

.label {
  font-weight: 700;
  text-align: center;
  font-size: 1.5rem;
  color: white;
}
.label-mobile {
  font-size: 1rem;
}

.label div {
  position: relative;
}

.label1 { left: 20%; bottom: 1em;}
.label2 { left: 20%; bottom: 0.8em}
.label3 { left: 30%; bottom: 0.6em;}
.label4 { left: 10%; bottom: 0.2em;}
.label5 { left: -45%; bottom: 0;}
.label6 { left: -50%; bottom: 0;}
.label7 { left: -80%; bottom: 0;}
.label8 { left: -100%; bottom: 0.1em;}
.label9 { left: -120%; bottom: 0.7em;}
.label10 { left: -110%; bottom: 1em;}
.label11 { left: -50%; bottom: 1.7em;}
.label12 { left: -50%; bottom: 1.6em;}

.leaflet-pane{
  position: absolute;
}

.block0 {
  background: #00FFFF;
  padding: 8px;
  margin-right: 8px;
}

.block1 {
  background: #faa142;
  padding: 8px;
  margin-right: 8px;
}

.block2 {
  background: #f34949;
  padding: 8px;
  margin-right: 0px;
}
</style>