<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>

        <ion-img src="../assets/icon/icon.png" slot="start" height="64px"></ion-img>
        <ion-title>{{ title }}</ion-title>

        <ion-buttons slot="end">
          <ion-button @click="searchClicked()" fill="outline" color="primary">
            <ion-icon name="search-sharp"></ion-icon>
          </ion-button>
<!--          <ion-button @click="toggleMap()" fill="outline" color="primary">-->
<!--            <ion-icon name="globe-outline"></ion-icon>-->
<!--          </ion-button>-->
        </ion-buttons>


      </ion-toolbar>
      <ion-text v-if="showAddress">{{ address }}</ion-text>
      <ion-searchbar ref="searchBar" v-if="showSearch" @changed="searchChanged()" placeholder="Kia Orana" @ionInput="searchTextChanged" debounce="500" autofocus></ion-searchbar>
      <ion-list v-show="showList">
        <ion-item :key="item" v-for="item in searchResults" @click="searchSelected(item)">{{ item }}</ion-item>
      </ion-list>
    </ion-header>

    <ion-content fullscreen="true">
      <RadialMapView ref="map" @onMapUpdated="onMapUpdated" />
      <ion-alert
          :is-open="dialogIsOpen"
          header="BETA WARNING"
          message="Currently Raro Guide for the evaluation and may change. See help for more details."
          css-class="my-custom-class"
          :buttons="dialogButtons"
          @onDidDismiss="setDialogOpenState(false)"
          color="warning"
      >
      </ion-alert>
    </ion-content>

  </ion-page>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { addIcons } from "ionicons";
import { settings, help, mapOutline, globeOutline } from "ionicons/icons";

addIcons({
  "settings": settings,
  "help": help,
  "map-outline": mapOutline,
  "globe-outline": globeOutline,
});

import { IonContent, IonHeader, IonFooter, IonPage, IonTitle, IonToolbar,
  IonButtons, IonButton, IonIcon, IonSearchbar, IonText, IonList, IonItem,
  IonGrid, IonRow, IonCol,
  IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonAlert, IonImg, IonAvatar } from '@ionic/vue';
import { defineComponent } from 'vue';

import RadialMapView from '@/components/RadialMapView.vue';

export default defineComponent({
  name: 'Home',
  components: {
    RadialMapView,
    IonContent,
    IonHeader,
    IonFooter,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonSearchbar,
    IonText,
    IonList,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonAlert,
    IonImg,
    IonAvatar

  },
  data() {
    return {
      title: "Raro Guide",
      map: null,
      tile_layer: null,
      address: "",
      showAddress: false,
      showSearch: false,
      searchText: "",
      showList: false,
      searchResults: [],
      mapId: "Map100",
      dialogIsOpen: true,
      dialogButtons: ['OK']
    };
  },
  methods: {
    log(msg) {
      console.log(msg);
    },
    onMapUpdated() {
      let details = this.$refs.map.radialMap.getAddress();
      if (details === "") return;
      this.title = details + " Blk";
    },
    searchSelected: function(item) {
      this.searchResults = [];
      this.showSearch = false;
      this.showList = false;
      this.$refs.map.setAddress(item);
    },
    searchClicked: function() {
      if (this.showSearch) {
        this.showSearch = false;
      } else {
        this.showAddress = false;
        this.showSearch = true;
      }
    },
    toggleMap: function() {
      this.mapId = this.$refs.map.toggleRadialMap();
    },
    searchTextChanged(ev) {
      this.searchResults = this.$refs.map.searchAddress(ev.target.value);
      this.showList = this.searchResults.length > 0;
    },
    setDialogOpenState(state) {
      this.dialogIsOpen = state;
    }
  },
  ionViewDidEnter() {
    console.log('Home page did enter');
  },
  mounted() {
    console.log("Home mounted");
    console.log(this.$router.currentRoute.value.name);
  },
  updated() {
    console.log("Home updated");
  },
  ionViewWillLeave() {
    console.log('Home page will leave');
  }
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

ion-img {
  margin: 10px 0px 10px 20px;
  height: 44px;
  width: 44px;
}

</style>